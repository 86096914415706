import { createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
  console.log("STORE", state, action);
  switch (action.type) {
    case 'INCREMENT':
      console.log("STORE increment", state);
      return Object.assign({}, state, {
        count: state.count + 1,
      }) 
      
      break;


      case `DECREMENT`:
        console.log("STORE decrement", state);
      return Object.assign({}, state, {
        count: state.count - 1,
      })
    break;

    case  `OPENSIDEBAR`:
        console.log("STORE open sidebar", state);
      return Object.assign({}, state, {
        sidebarOpened: true,
      })
      break;
    case  `CLOSESIDEBAR`:
        console.log("STORE close sidebar", state);
      return Object.assign({}, state, {
        sidebarOpened: false,
      })
      break;
    case  `TOGGLESIDEBAR`:
        console.log("STORE toggle sidebar", state);
      return Object.assign({}, state, {
        sidebarOpened: !state.sidebarOpened,
      })
      break;
    case  `TOGGLETHEME`:
        console.log("STORE toggle theme", state);
      return Object.assign({}, state, {
        preftheme: (state.preftheme == 'dark') ? 'light' : 'dark',
      })
      break;
    
    case `CURRENTLYPLAYING/SETSRC`:
        console.log("STORE currently playing set src", state, action);
      return Object.assign({}, state, {
        currentPlayingSrc: ((action.payload != undefined) ? action.payload.src : ""),
      })
      break;
    
  
    default:
      return state
      break;
  }

}

const initialState = { 
  count: 0, 
  sidebarOpened: false, 
  preftheme: 'dark', 
  currentPlayingSrc: '',
}

const createStore = () => {
  if(process.env.USE_DEBUGTOOLS == "true"){
  return reduxCreateStore(
    reducer, 
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()  
    )
  }
  return reduxCreateStore(
    reducer, 
    initialState
    )
}
export default createStore