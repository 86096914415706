/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import clsx from "clsx"

import Avatar from '@material-ui/core/Avatar'
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"

import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import MailIcon from "@material-ui/icons/Mail"

import HomeRoundedIcon from "@material-ui/icons/HomeRounded"
import QueueMusicRoundedIcon from "@material-ui/icons/QueueMusicRounded"
import HeadsetMicRoundedIcon from "@material-ui/icons/HeadsetMicRounded"
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded"
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"

import AniLink from "gatsby-plugin-transition-link/AniLink"

import { deepOrange, deepPurple } from '@material-ui/core/colors';

import { connect } from "react-redux"

import Header from "./header"
import CustomFooter from "../components/customfooter"
import "./layout.css"
import "../styles/main.scss"

import Helmet from 'react-helmet'


const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}))

const menuItems = ["Home", "Shows", "Create"]
const menuLinks = ["/", "/shows", "/"]
const menuIcons = [
  <HomeRoundedIcon />,
  <QueueMusicRoundedIcon />,
  <HeadsetMicRoundedIcon />,
]
const cfgItems = ["Settings", "Exit"]
const cfgLinks = ["/", "/"]
const cfgIcons = [<SettingsRoundedIcon />, <ExitToAppRoundedIcon />]

const list = (anchor, togglesidebar, classes) => (
  <div
    className={classes.fullList}
    role="presentation"
    onClick={togglesidebar}
    onKeyDown={togglesidebar}
  >
  <List>
  <ListItem className={classes.orange} key="profile">
    <ListItemIcon>
    <Avatar className={classes.purple}>RN</Avatar>
    </ListItemIcon>
    <ListItemText  primary={"Rodrigo"} />
  </ListItem>
  <Divider />
      {menuItems.map((text, index) => (
        <AniLink cover to={menuLinks[index]} key={index}>
          <ListItem button key={text}>
            <ListItemIcon>{menuIcons[index]}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        </AniLink>
      ))}
    </List>
    <Divider />
    <List>
      {cfgItems.map((text, index) => (
        <AniLink cover to={cfgLinks[index]} key={index}>
          <ListItem button key={text}>
            <ListItemIcon>{cfgIcons[index]}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        </AniLink>
      ))}
    </List>
  </div>
)

const CustomBackdrop = ({ sidebarOpened, togglesidebar, classes }) => (
  <React.Fragment key="left">
    <Drawer anchor="left" open={sidebarOpened} onClose={togglesidebar}>
      {list("left", togglesidebar, classes)}
    </Drawer>
  </React.Fragment>
)

CustomBackdrop.propTypes = {
  sidebarOpened: PropTypes.bool.isRequired,
  togglesidebar: PropTypes.func.isRequired,
}

const mapStateToProps = ({ sidebarOpened, preftheme }, ownProps) => {
  const { classes } = ownProps
  return { sidebarOpened, preftheme, classes }
}

const mapDispatchToProps = dispatch => {
  return { togglesidebar: () => dispatch({ type: `TOGGLESIDEBAR` }) }
}

const ConnectedCustomBackdrop = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomBackdrop)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleToggle = () => {
    setOpen(!open)
  }

  //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const prefersDarkMode = false

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  )
  /*
  */
 
  return (
    <>
    <Helmet
    bodyAttributes={{
        class: 'has-navbar-fixed-top'
    }}
    />
  <ThemeProvider theme={theme}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
        >
        
        <main>{children}</main>
        <ConnectedCustomBackdrop classes={classes} />
        
        <CustomFooter></CustomFooter>
        </div>
        </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
