import {
  createMuiTheme
} from "@material-ui/core";

const theme = createMuiTheme({
  "palette": {
    "common": {
      "black": "#000",
      "white": "#fff"
    },
    "background": {
      "paper": "rgba(155, 155, 155, 1)",
      "default": "rgba(74, 74, 74, 1)"
    },
    "primary": {
      "light": "rgba(218, 151, 231, 1)",
      "main": "rgba(147, 14, 173, 1)",
      "dark": "rgba(60, 3, 71, 1)",
      "contrastText": "#fff"
    },
    "secondary": {
      "light": "#ff4081",
      "main": "rgba(79, 131, 24, 1)",
      "dark": "#c51162",
      "contrastText": "#fff"
    },
    "error": {
      "light": "#e57373",
      "main": "rgba(208, 2, 27, 1)",
      "dark": "#d32f2f",
      "contrastText": "#fff"
    },
    "text": {
      "primary": "rgba(0, 0, 0, 0.87)",
      "secondary": "rgba(0, 0, 0, 0.54)",
      "disabled": "rgba(0, 0, 0, 0.38)",
      "hint": "rgba(0, 0, 0, 0.38)"
    }
  }
});

export default theme;