/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import clsx from "clsx"

import { makeStyles } from "@material-ui/core/styles"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import Grow from "@material-ui/core/Grow"

import AudioPlayer, { RHAP_UI } from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"

import { connect } from "react-redux"

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}))

const CustomAudioplayer = ({ currentPlayingSrc, preftheme, classname }) => {
  const classes = useStyles()

  //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const prefersDarkMode = preftheme == "dark"

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  )

  if (currentPlayingSrc == "") {
    return ""
  }

  /*
  <Grow>
    <ThemeProvider theme={theme}>

    </ThemeProvider>
   </Grow>
  */
  return (
        <AudioPlayer
          autoPlay
          src={currentPlayingSrc}
          onPlay={e => console.log("LAYOUT onPlay")}
          customProgressBarSection={
            [
              RHAP_UI.CURRENT_TIME,
              <div>/</div>,
              RHAP_UI.DURATION,
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.VOLUME,
            ]
          }
          customVolumeControls={[]}
        />
  )
}

CustomAudioplayer.propTypes = {
  currentPlayingSrc: PropTypes.string.isRequired,
  preftheme: PropTypes.string.isRequired,
}

const mapStateToProps = ({ currentPlayingSrc, preftheme }, ownProps) => {
  const { classname } = ownProps
  return { currentPlayingSrc, preftheme, classname }
}

/*
    const mapDispatchToProps = dispatch => {
      return { togglesidebar: () => dispatch({ type: `TOGGLESIDEBAR` }) }
    }
    */

//const ConnectedCustomAudioplayer = connect(mapStateToProps, mapDispatchToProps)(CustomAudioplayer)
const ConnectedCustomAudioplayer = connect(mapStateToProps)(CustomAudioplayer)

const Player = ({ children }) => <ConnectedCustomAudioplayer />

Player.propTypes = {
  children: PropTypes.node,
}

export default Player
